import { createContext, FC, useEffect, useState } from "react";
import { MemberRole, UserRole } from "../slices/models";
import {
   useAuthenticateUser,
   useAuthenticateUserOAuth2,
   useGetUnion,
   useGetUser,
   useLogout,
   useRegisterUser,
   useSendVerificationCode,
   useVerifyUserCode,
} from "../hooks/use-api";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
   HubConnection,
   HubConnectionBuilder,
   HubConnectionState,
} from "@microsoft/signalr";
import { IHttpConnectionOptions } from "@microsoft/signalr/dist/esm/IHttpConnectionOptions";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "../hooks/use-router";
import { paths } from "../paths";
import {
   OverridableTokenClientConfig,
   useGoogleLogin,
} from "@react-oauth/google";

interface IUserContextGeoLocation {
   latitude: number;
   longitude: number;
   source: "browser" | "ipaddress" | "";
}
export interface IUserContext {
   geoLocation: IUserContextGeoLocation;
   isAuthenticated: boolean;
   isInitialized: boolean;
   isLoggingOut: boolean;
   unionKey: number;
   unionRole: MemberRole;
   unionName: string;
   userKey: number;
   name: string;
   role: UserRole;
   email: string;
   token: string;
   setGeoLocation: (geoLocation: IUserContextGeoLocation) => Promise<void>;
   login: (email: string, password: string) => Promise<boolean>;
   loginOAuth2: (provider: string, credential: any) => Promise<boolean>;
   loginGoogle: (overrideConfig?: OverridableTokenClientConfig) => void;
   logout: () => Promise<void>;
   register: (email: string, name: string, password: string) => Promise<void>;
   verify: (
      email: string,
      password: string,
      verificationCode: string,
   ) => Promise<boolean>;
   sendVerificationCode: (email: string) => Promise<void>;
}

interface IUserContextProps {
   children: any;
}

export const getFingerprintJSClient = async () => {
   const client = await FingerprintJS.load();
   return await client.get();
};

export const UserContext = createContext<IUserContext>(null);

export const UserProvider: FC<IUserContextProps> = ({ children }) => {
   const queryClient = useQueryClient();
   const router = useRouter();
   const setGeoLocation = async (geoLocation: IUserContextGeoLocation) => {
      setGeoLocationState(geoLocation);
   };

   const login = async (email: string, password: string) => {
      const visitorId = await getFingerprintJSClient().then(
         (client) => client.visitorId,
      );
      let data = await loginMutation.mutateAsync({
         email,
         password,
         fingerprint: visitorId,
      });
      if (data?.token) {
         localStorage.setItem("accessToken", data.token);
         await refetchUser();
         return true;
      } else {
         return false; // user needs to verify their account
      }
   };

   const loginOAuth2 = async (provider: string, credential: any) => {
      const visitorId = await getFingerprintJSClient().then(
         (client) => client.visitorId,
      );
      let data = await loginOAuth2Mutation.mutateAsync({
         email: "",
         provider: provider,
         credential: credential,
         fingerprint: visitorId,
      });
      if (data?.token) {
         localStorage.setItem("accessToken", data.token);
         await refetchUser();
         return true;
      } else {
         return false; // user needs to verify their account
      }
   };

   const loginGoogle = useGoogleLogin({
      onSuccess: async (tokenResponse) => {
         const isLoggedIn = await loginOAuth2(
            "google",
            tokenResponse.access_token,
         );
         if (isLoggedIn === true) {
            const returnUrl =
               (router.query.returnUrl as string) || "/dashboard";
            await router.push(returnUrl).catch(console.error);
         } else {
         }
      },
   });

   const logout = async () => {
      setIsLoggingOut(true);
      await wsConnection.stop();
      localStorage.removeItem("accessToken");
      await router.push(paths.index).then(async () => {
         await logoutMutation
            .mutateAsync()
            .then(() => {
               queryClient.clear();
            })
            .then(() => location.reload())
            .then(() => setIsLoggingOut(false));
      });
   };

   const register = async (email: string, name: string, password: string) => {
      const data = await registerMutation.mutateAsync({
         name,
         email,
         password,
      });
   };

   const sendVerificationCode = async (email: string) => {
      const data = await sendVerificationCodeMutation.mutateAsync({ email });
   };

   const verify = async (
      email: string,
      password: string,
      verificationCode: string,
   ) => {
      const data = await verifyUserCodeMutation.mutateAsync({
         email,
         verificationCode,
      });
      if (email != "" && password != "") {
         return await login(email, password);
      }
      return false;
   };

   const defaultState: IUserContext = {
      geoLocation: {
         latitude: 0,
         longitude: 0,
         source: "",
      },
      isAuthenticated: false,
      isInitialized: false,
      isLoggingOut: false,
      unionKey: 0,
      unionRole: null,
      unionName: "",
      userKey: 0,
      name: "",
      role: null,
      email: "",
      token: "",
      setGeoLocation: setGeoLocation,
      login: login,
      loginOAuth2: loginOAuth2,
      loginGoogle: loginGoogle,
      logout: logout,
      register: register,
      verify: verify,
      sendVerificationCode: sendVerificationCode,
   };

   const [geoLocationState, setGeoLocationState] =
      useState<IUserContextGeoLocation>(defaultState.geoLocation);
   const [wsConnection, setWsConnection] = useState<null | HubConnection>(null);
   const [isLoggingOut, setIsLoggingOut] = useState(false);

   // Queries
   const {
      data: user,
      refetch: refetchUser,
      isLoading: isUserLoading,
   } = useGetUser();
   const { data: union, isLoading: isUserUnionLoading } = useGetUnion({
      unionKey: user?.defaultUnion,
   });

   // Mutations
   const logoutMutation = useLogout();
   const loginMutation = useAuthenticateUser();
   const loginOAuth2Mutation = useAuthenticateUserOAuth2();
   const registerMutation = useRegisterUser();
   const verifyUserCodeMutation = useVerifyUserCode();
   const sendVerificationCodeMutation = useSendVerificationCode();

   // Web Sockets
   const createWebSocketConnection = async (newToken: string = null) => {
      if (
         !wsConnection ||
         wsConnection.state == HubConnectionState.Disconnected
      ) {
         let options: IHttpConnectionOptions = {
            accessTokenFactory: async function () {
               let token = newToken ? newToken : user.token;
               if (!token) {
                  token = globalThis.localStorage.getItem("accessToken");
               }
               return token;
            },
         };
         const connect = new HubConnectionBuilder()
            .withUrl(process.env.NEXT_PUBLIC_EXTERNAL_API_URL + "/ws", options)
            .withAutomaticReconnect()
            .build();

         setWsConnection(connect);
      }
   };

   useEffect(() => {
      if (wsConnection && !!user?.token) {
         wsConnection
            .start()
            .then(() => {
               wsConnection.on("InvalidateCacheTags", (cacheTags: any) => {
                  cacheTags.map((cacheTag: any) => {
                     queryClient.invalidateQueries({
                        queryKey: cacheTag.keys,
                     });
                  });
               });
            })
            .catch((error) => console.log(error));
      }
   }, [wsConnection]);

   useEffect(() => {
      const initialize = async () => {
         if (user?.token) {
            await createWebSocketConnection(user.token);
         } else {
            let token = globalThis.localStorage.getItem("accessToken");
            if (token) {
               await createWebSocketConnection(token);
            }
         }
      };
      initialize();
   }, [user?.token]);

   return (
      <UserContext.Provider
         value={{
            geoLocation: geoLocationState,
            isAuthenticated: !!user?.token || false,
            isInitialized:
               !isUserLoading &&
               (!isUserUnionLoading || user?.defaultUnion == 0),
            isLoggingOut: isLoggingOut,
            unionKey: user?.defaultUnion,
            unionRole: union
               ? union.members.find((member) => member.userKey == user?.userKey)
                    ?.role
               : null,
            unionName: union?.name,
            userKey: user?.userKey,
            name: user?.name,
            role: user?.role,
            email: user?.email,
            token: user?.token,
            setGeoLocation: setGeoLocation,
            login: login,
            loginOAuth2: loginOAuth2,
            loginGoogle: loginGoogle,
            logout: logout,
            register: register,
            verify: verify,
            sendVerificationCode: sendVerificationCode,
         }}
      >
         {children}
      </UserContext.Provider>
   );
};

export const UserConsumer = UserContext.Consumer;
