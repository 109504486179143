// Remove if react-quill is not used
import "react-quill/dist/quill.snow.css";
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
import "mapbox-gl/dist/mapbox-gl.css";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import type { EmotionCache } from "@emotion/react";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { RTL } from "src/components/rtl";
import { SplashScreen } from "src/components/splash-screen";
import { Toaster } from "src/components/toaster";
import { gtmConfig } from "src/config";
import { UserConsumer, UserProvider } from "src/contexts/user-context";
import { SettingsConsumer, SettingsProvider } from "src/contexts/settings";
import { useAnalytics } from "src/hooks/use-analytics";
import { useNprogress } from "src/hooks/use-nprogress";
import { createTheme } from "src/theme";
import { createEmotionCache } from "src/utils/create-emotion-cache";

import "src/global.css";
// Remove if locales are not used
import "src/locales/i18n";
const clientSideEmotionCache = createEmotionCache();
import {
   HydrationBoundary,
   QueryClient,
   QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
export interface CustomAppProps extends AppProps {
   Component: NextPage;
   emotionCache?: EmotionCache;
}

const CustomApp = (props: CustomAppProps) => {
   const {
      Component,
      emotionCache = clientSideEmotionCache,
      pageProps,
   } = props;

   const [queryClient] = useState(
      () =>
         new QueryClient({
            defaultOptions: {
               queries: {
                  refetchOnWindowFocus: false,
                  staleTime: 60 * 1000,
               },
            },
         }),
   );

   useAnalytics(gtmConfig);
   useNprogress();

   const getLayout = Component.getLayout ?? ((page) => page);

   return (
      <QueryClientProvider client={queryClient}>
         <HydrationBoundary state={pageProps.dehydratedState}>
            <CacheProvider value={emotionCache}>
               <Head>
                  <title>Union Near Me</title>
                  <meta
                     name="viewport"
                     content="initial-scale=1, width=device-width"
                  />
               </Head>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <GoogleOAuthProvider clientId="440592259555-85ndon7gos9j6sgcfla1a4r8at899jca.apps.googleusercontent.com">
                        <UserProvider>
                           <UserConsumer>
                              {(auth) => (
                                 <SettingsProvider>
                                    <SettingsConsumer>
                                       {(settings) => {
                                          // Prevent theme flicker when restoring custom settings from browser storage
                                          if (!settings.isInitialized) {
                                             // return null;
                                          }

                                          const theme = createTheme({
                                             colorPreset: settings.colorPreset,
                                             contrast: settings.contrast,
                                             direction: settings.direction,
                                             paletteMode: settings.paletteMode,
                                             responsiveFontSizes:
                                                settings.responsiveFontSizes,
                                          });

                                          // Prevent guards from redirecting
                                          const showSlashScreen = false; //!auth.isInitialized;

                                          return (
                                             <ThemeProvider theme={theme}>
                                                <Head>
                                                   <meta
                                                      name="color-scheme"
                                                      content={
                                                         settings.paletteMode
                                                      }
                                                   />
                                                   <meta
                                                      name="theme-color"
                                                      content={"#6366F1"}
                                                   />
                                                </Head>
                                                <RTL
                                                   direction={
                                                      settings.direction
                                                   }
                                                >
                                                   <CssBaseline />
                                                   {showSlashScreen ? (
                                                      <SplashScreen />
                                                   ) : (
                                                      <>
                                                         {getLayout(
                                                            <Component
                                                               {...pageProps}
                                                            />,
                                                         )}
                                                         {/*<SettingsButton*/}
                                                         {/*   onClick={*/}
                                                         {/*      settings.handleDrawerOpen*/}
                                                         {/*   }*/}
                                                         {/*/>*/}
                                                         {/*<SettingsDrawer*/}
                                                         {/*   canReset={*/}
                                                         {/*      settings.isCustom*/}
                                                         {/*   }*/}
                                                         {/*   onClose={*/}
                                                         {/*      settings.handleDrawerClose*/}
                                                         {/*   }*/}
                                                         {/*   onReset={*/}
                                                         {/*      settings.handleReset*/}
                                                         {/*   }*/}
                                                         {/*   onUpdate={*/}
                                                         {/*      settings.handleUpdate*/}
                                                         {/*   }*/}
                                                         {/*   open={*/}
                                                         {/*      settings.openDrawer*/}
                                                         {/*   }*/}
                                                         {/*   values={{*/}
                                                         {/*      colorPreset:*/}
                                                         {/*         settings.colorPreset,*/}
                                                         {/*      contrast:*/}
                                                         {/*         settings.contrast,*/}
                                                         {/*      direction:*/}
                                                         {/*         settings.direction,*/}
                                                         {/*      paletteMode:*/}
                                                         {/*         settings.paletteMode,*/}
                                                         {/*      responsiveFontSizes:*/}
                                                         {/*         settings.responsiveFontSizes,*/}
                                                         {/*      stretch:*/}
                                                         {/*         settings.stretch,*/}
                                                         {/*      layout:*/}
                                                         {/*         settings.layout,*/}
                                                         {/*      navColor:*/}
                                                         {/*         settings.navColor,*/}
                                                         {/*   }}*/}
                                                         {/*/>*/}
                                                      </>
                                                   )}
                                                   <Toaster />
                                                </RTL>
                                             </ThemeProvider>
                                          );
                                       }}
                                    </SettingsConsumer>
                                 </SettingsProvider>
                              )}
                           </UserConsumer>
                        </UserProvider>
                     </GoogleOAuthProvider>
                  </LocalizationProvider>
            </CacheProvider>
         </HydrationBoundary>
         <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
   );
};

export default CustomApp;
