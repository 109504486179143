export const paths = {
   index: "/",
   map: "/map",
   checkout: "/checkout",
   contact: "/contact",
   pricing: "/pricing",
   sitemap: "/sitemap_index.xml",
   termsAndConditions: "/terms-and-conditions",
   privacyPolicy: "/privacy",
   userDataDeleteRequest: "/data-deletion",

   countries: "/countries",
   country: (country: string) => {
      return `/country/${country}`;
   },
   location: (locationKey: number) => {
      return `/${locationKey}`;
   },
   associateBusinessToLocation: (locationKey: number) => {
      return `/${locationKey}/add`;
   },
   business: (locationKey: number, businessKey: number) => {
      return `/${locationKey}/${businessKey}`;
   },
   union: (locationKey: number, businessKey: number, unionKey: number) => {
      return `/${locationKey}/${businessKey}/${unionKey}`;
   },
   businessUnionLeaders: (locationKey: number, businessKey: number) => {
      return `/${locationKey}/${businessKey}/union-leaders`;
   },
   unions: (locationKey: number, businessKey: number) => {
      return `/${locationKey}/${businessKey}/unions`;
   },
   joinUnion: (locationKey: number, businessKey: number, unionKey: number) => {
      return `/${locationKey}/${businessKey}/${unionKey}/join`;
   },
   createUnion: (locationKey: number, businessKey: number) => {
      return `/${locationKey}/${businessKey}/union/create`;
   },
   unionMembers: (
      locationKey: number,
      businessKey: number,
      unionKey: number,
   ) => {
      return `/${locationKey}/${businessKey}/${unionKey}/members`;
   },
   region: (country: string, region: string) => {
      return `/country/${country}/region/${region}`;
   },
   blog: {
      index: "/blog",
      post: (slug: string) => {
         return `/blog/${slug}`;
      },
   },
   auth: {
      credentials: {
         login: "/authentication/login",
         register: "/authentication/register",
         passwordReset: "/authentication/password-reset",
         passwordRecovery: "/authentication/password-recovery",
         verify: "/authentication/verify",
      },
   },
   dashboard: {
      index: "/dashboard",
      academy: {
         index: "/dashboard/academy",
         courseDetails: "/dashboard/academy/courses/:courseId",
      },
      account: "/dashboard/account",
      analytics: "/dashboard/analytics",
      blank: "/dashboard/blank",
      calendar: "/dashboard/calendar",
      chat: "/dashboard/chat",
      crypto: "/dashboard/crypto",
      management: "/dashboard/management",
      moderation: {
         index: "/dashboard/moderation",
         businesses: "/dashboard/moderation/businesses",
      },
      administration: {
         tools: {
            updateBusinessMetadata:
               "/dashboard/administration/tools/update-business-metadata",
         },
      },
      members: {
         index: "/dashboard/members",
         invite: "/dashboard/members/invite",
         member: (memberKey: number) => {
            return `/dashboard/members/${memberKey}`;
         },
      },
      proposals: {
         index: "/dashboard/proposals",
         create: "/dashboard/proposals/create",
         proposal: (proposalKey: number) => {
            return `/dashboard/proposals/${proposalKey}`;
         },
      },
      actions: {
         index: "/dashboard/actions",
         action: (actionKey: number) => {
            return `/dashboard/actions/${actionKey}`;
         },
      },
      elections: {
         index: "/dashboard/elections",
         election: (electionKey: number) => {
            return `/dashboard/elections/${electionKey}`;
         },
      },
      ecommerce: "/dashboard/ecommerce",
      fileManager: "/dashboard/file-manager",
      invoices: {
         index: "/dashboard/invoices",
         details: "/dashboard/invoices/:orderId",
      },
      jobs: {
         index: "/dashboard/jobs",
         create: "/dashboard/jobs/create",
         companies: {
            details: "/dashboard/jobs/companies/:companyId",
         },
      },
      kanban: "/dashboard/kanban",
      logistics: {
         index: "/dashboard/logistics",
         fleet: "/dashboard/logistics/fleet",
      },
      mail: "/dashboard/mail",
      orders: {
         index: "/dashboard/orders",
         details: "/dashboard/orders/:orderId",
      },
      products: {
         index: "/dashboard/products",
         create: "/dashboard/products/create",
      },
      social: {
         index: "/dashboard/social",
         profile: "/dashboard/social/profile",
         feed: "/dashboard/social/feed",
      },
   },
   components: {
      index: "/components",
      dataDisplay: {
         detailLists: "/components/data-display/detail-lists",
         tables: "/components/data-display/tables",
         quickStats: "/components/data-display/quick-stats",
      },
      lists: {
         groupedLists: "/components/lists/grouped-lists",
         gridLists: "/components/lists/grid-lists",
      },
      forms: "/components/forms",
      modals: "/components/modals",
      charts: "/components/charts",
      buttons: "/components/buttons",
      typography: "/components/typography",
      colors: "/components/colors",
      inputs: "/components/inputs",
   },
   docs: "https://material-kit-pro-react-docs.renter.services",
   openStreetMapsCopyright: "https://www.openstreetmap.org/copyright",
   notAuthorized: "/401",
   notFound: "/404",
   serverError: "/500",
};
