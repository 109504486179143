import { tokens } from "../tokens";

export const en = {
   [tokens.common.languageChanged]: "Language changed",
   [tokens.nav.academy]: "Academy",
   [tokens.nav.account]: "Account",
   [tokens.nav.analytics]: "Analytics",
   [tokens.nav.auth]: "Auth",
   [tokens.nav.blog]: "Blog",
   [tokens.nav.browse]: "Browse",
   [tokens.nav.calendar]: "Calendar",
   [tokens.nav.chat]: "Chat",
   [tokens.nav.checkout]: "Checkout",
   [tokens.nav.union]: "Union",
   [tokens.nav.contact]: "Contact",
   [tokens.nav.course]: "Course",
   [tokens.nav.create]: "Create",
   [tokens.nav.crypto]: "Crypto",
   [tokens.nav.members]: "Members",
   [tokens.nav.invite]: "Invite",
   [tokens.nav.dashboard]: "Dashboard",
   [tokens.nav.actions]: "Actions",
   [tokens.nav.elections]: "Elections",
   [tokens.nav.proposals]: "Proposals",
   [tokens.nav.details]: "Details",
   [tokens.nav.ecommerce]: "E-Commerce",
   [tokens.nav.edit]: "Edit",
   [tokens.nav.error]: "Error",
   [tokens.nav.feed]: "Feed",
   [tokens.nav.fileManager]: "File Manager",
   [tokens.nav.finance]: "Finance",
   [tokens.nav.fleet]: "Fleet",
   [tokens.nav.forgotPassword]: "Forgot Password",
   [tokens.nav.invoiceList]: "Invoices",
   [tokens.nav.jobList]: "Job Listings",
   [tokens.nav.kanban]: "Kanban",
   [tokens.nav.list]: "List",
   [tokens.nav.login]: "Login",
   [tokens.nav.logistics]: "Logistics",
   [tokens.nav.mail]: "Mail",
   [tokens.nav.management]: "Management",
   [tokens.nav.orderList]: "Orders",
   [tokens.nav.overview]: "Overview",
   [tokens.nav.pages]: "Pages",
   [tokens.nav.representative]: "Representative",
   [tokens.nav.postCreate]: "Post Create",
   [tokens.nav.postDetails]: "Post Details",
   [tokens.nav.postList]: "Post List",
   [tokens.nav.pricing]: "Pricing",
   [tokens.nav.productList]: "Products",
   [tokens.nav.profile]: "Profile",
   [tokens.nav.register]: "Register",
   [tokens.nav.resetPassword]: "Reset Password",
   [tokens.nav.socialMedia]: "Social Media",
   [tokens.nav.verifyCode]: "Verify Code",
   [tokens.nav.administration]: "Administration",
   [tokens.nav.moderation]: "Moderation",
   [tokens.nav.businesses]: "Businesses",
   [tokens.nav.tools]: "Tools",
   [tokens.nav.updateBusinessMetadata]: "Update Business Metadata",
};
