import * as models from "../slices/models";
import axios from "axios";
import * as cookie from "cookie";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/query-core";
export const useGetActions = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblAction', 'List', 'GetActions', payload],
      queryFn: () => api.getActions(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetAction = (payload: {actionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ActionKey', payload.actionKey, 'GetAction', payload],
      queryFn: () => api.getAction(payload),
      enabled: !!payload.actionKey,
    });
};
export const useAddActionProposal = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addActionProposal });
};
export const useWithdrawActionProposal = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.withdrawActionProposal });
};
export const useGetMemberActionVotes = (payload: {memberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetMemberActionVotes', payload],
      queryFn: () => api.getMemberActionVotes(payload),
      enabled: !!payload.memberKey,
    });
};
export const useAddActionVote = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addActionVote });
};
export const useWithdrawActionVote = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.withdrawActionVote });
};
export const useUpdateMetadataForBusiness = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.updateMetadataForBusiness });
};
export const useRegisterUser = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.registerUser });
};
export const useSendVerificationCode = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.sendVerificationCode });
};
export const useVerifyUserCode = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.verifyUserCode });
};
export const useGetFakeName = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetFakeName'],
      queryFn: () => api.getFakeName(),
      enabled: true,
    });
};
export const useGetIpLocation = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetIpLocation'],
      queryFn: () => api.getIpLocation(),
      enabled: true,
    });
};
export const useAuthenticateUserOAuth2 = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.authenticateUserOAuth2 });
};
export const useAuthenticateUser = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.authenticateUser });
};
export const useLogout = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.logout });
};
export const useReAuthenticateUser = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.reAuthenticateUser });
};
export const useUpdatePassword = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.updatePassword });
};
export const useRecoverPassword = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.recoverPassword });
};
export const useResetPassword = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.resetPassword });
};
export const useGetBenefits = (payload: {locationKey: number, businessKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['BusinessKey', payload.businessKey, 'tblBenefit', 'List', 'GetBenefits', payload],
      queryFn: () => api.getBenefits(payload),
      enabled: !!payload.locationKey && !!payload.businessKey,
    });
};
export const useAddBenefit = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addBenefit });
};
export const useGetBusiness = (payload: {businessKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['BusinessKey', payload.businessKey, 'GetBusiness', payload],
      queryFn: () => api.getBusiness(payload),
      enabled: !!payload.businessKey,
    });
};
export const useGetNoMetadataBusinesses = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['tblBusiness', 'List', 'GetNoMetadataBusinesses'],
      queryFn: () => api.getNoMetadataBusinesses(),
      enabled: true,
    });
};
export const useGetUnReviewedBusinesses = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['tblBusiness', 'List', 'GetUnReviewedBusinesses'],
      queryFn: () => api.getUnReviewedBusinesses(),
      enabled: true,
    });
};
export const useSearchBusinesses = (payload: {search: string}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['SearchBusinesses', payload],
      queryFn: () => api.searchBusinesses(payload),
      enabled: !!payload.search,
    });
};
export const useAddBusiness = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addBusiness });
};
export const useGetCalendarEvents = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblCalendarEvent', 'List', 'GetCalendarEvents', payload],
      queryFn: () => api.getCalendarEvents(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetChatMembers = (payload: {chatThreadKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ChatThreadKey', payload.chatThreadKey, 'tblChatMember', 'List', 'GetChatMembers', payload],
      queryFn: () => api.getChatMembers(payload),
      enabled: !!payload.chatThreadKey,
    });
};
export const useGetChatMember = (payload: {chatMemberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ChatMemberKey', payload.chatMemberKey, 'GetChatMember', payload],
      queryFn: () => api.getChatMember(payload),
      enabled: !!payload.chatMemberKey,
    });
};
export const useAddChatMember = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addChatMember });
};
export const useGetUnreadMessages = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['tblChatMessage', 'List', 'GetUnreadMessages'],
      queryFn: () => api.getUnreadMessages(),
      enabled: true,
    });
};
export const useGetChatMessages = (payload: {chatThreadKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatMessages', payload],
      queryFn: () => api.getChatMessages(payload),
      enabled: !!payload.chatThreadKey,
    });
};
export const useGetChatMessage = (payload: {chatMessageKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ChatMessageKey', payload.chatMessageKey, 'GetChatMessage', payload],
      queryFn: () => api.getChatMessage(payload),
      enabled: !!payload.chatMessageKey,
    });
};
export const useAddChatMessageNewThread = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addChatMessageNewThread });
};
export const useAddChatMessage = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addChatMessage });
};
export const useGetChatThreads = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblChatThread', 'List', 'GetChatThreads', payload],
      queryFn: () => api.getChatThreads(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetChatThread = (payload: {chatThreadKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatThread', payload],
      queryFn: () => api.getChatThread(payload),
      enabled: !!payload.chatThreadKey,
    });
};
export const useAddChatThread = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addChatThread });
};
export const useAddElectionCandidacy = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addElectionCandidacy });
};
export const useWithdrawElectionCandidacy = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.withdrawElectionCandidacy });
};
export const useGetElections = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblElection', 'List', 'GetElections', payload],
      queryFn: () => api.getElections(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetElection = (payload: {electionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['ElectionKey', payload.electionKey, 'GetElection', payload],
      queryFn: () => api.getElection(payload),
      enabled: !!payload.electionKey,
    });
};
export const useGetMemberElectionVotes = (payload: {memberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetMemberElectionVotes', payload],
      queryFn: () => api.getMemberElectionVotes(payload),
      enabled: !!payload.memberKey,
    });
};
export const useAddElectionVote = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addElectionVote });
};
export const useWithdrawElectionVote = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.withdrawElectionVote });
};
export const useGetLocationURL = (payload: {houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, latitude: number, longitude: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetLocationURL', payload],
      queryFn: () => api.getLocationURL(payload),
      enabled: !!payload.houseNumber && !!payload.street && !!payload.city && !!payload.region && !!payload.postCode && !!payload.country && !!payload.latitude && !!payload.longitude,
    });
};
export const useGetLocationsInCoordBoundingBox = (payload: {bottomLat: number, topLat: number, leftLon: number, rightLon: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetLocationsInCoordBoundingBox', payload],
      queryFn: () => api.getLocationsInCoordBoundingBox(payload),
      enabled: !!payload.bottomLat && !!payload.topLat && !!payload.leftLon && !!payload.rightLon,
    });
};
export const useGetLocationsByCountryAndRegion = (payload: {country: string, region: string}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetLocationsByCountryAndRegion', payload],
      queryFn: () => api.getLocationsByCountryAndRegion(payload),
      enabled: !!payload.country && !!payload.region,
    });
};
export const useGetLocationCountByCountry = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetLocationCountByCountry'],
      queryFn: () => api.getLocationCountByCountry(),
      enabled: true,
    });
};
export const useGetLocationCountByRegion = (payload: {country: string}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetLocationCountByRegion', payload],
      queryFn: () => api.getLocationCountByRegion(payload),
      enabled: !!payload.country,
    });
};
export const useGetLocation = (payload: {locationKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['LocationKey', payload.locationKey, 'GetLocation', payload],
      queryFn: () => api.getLocation(payload),
      enabled: !!payload.locationKey,
    });
};
export const useGetLocationBusinesses = (payload: {locationKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['LocationKey', payload.locationKey, 'GetLocationBusinesses', payload],
      queryFn: () => api.getLocationBusinesses(payload),
      enabled: !!payload.locationKey,
    });
};
export const useAddLocation = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addLocation });
};
export const useAddLocationToBusiness = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addLocationToBusiness });
};
export const useGetUnionMembers = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetUnionMembers', payload],
      queryFn: () => api.getUnionMembers(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetUnionMember = (payload: {memberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetUnionMember', payload],
      queryFn: () => api.getUnionMember(payload),
      enabled: !!payload.memberKey,
    });
};
export const useGetUnionLeaders = (payload: {businessKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetUnionLeaders', payload],
      queryFn: () => api.getUnionLeaders(payload),
      enabled: !!payload.businessKey,
    });
};
export const useAddUnionMember = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addUnionMember });
};
export const useInviteUnionMember = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.inviteUnionMember });
};
export const useGetMemberBusinesses = (payload: {userKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UserKey', payload.userKey, 'GetMemberBusinesses', payload],
      queryFn: () => api.getMemberBusinesses(payload),
      enabled: !!payload.userKey,
    });
};
export const useGetMemberLocations = (payload: {userKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UserKey', payload.userKey, 'GetMemberLocations', payload],
      queryFn: () => api.getMemberLocations(payload),
      enabled: !!payload.userKey,
    });
};
export const useReviewBusiness = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.reviewBusiness });
};
export const useGetNotifications = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['tblNotification', 'List', 'GetNotifications'],
      queryFn: () => api.getNotifications(),
      enabled: true,
    });
};
export const useDismissNotification = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.dismissNotification });
};
export const useDismissAllNotifications = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.dismissAllNotifications });
};
export const useCreateCheckoutSession = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.createCheckoutSession });
};
export const useCreateCustomerPortal = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.createCustomerPortal });
};
export const usePaymentEvents = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.paymentEvents });
};
export const useGetProposals = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'GetProposals', payload],
      queryFn: () => api.getProposals(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetMemberProposals = (payload: {unionKey: number, memberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposals', payload],
      queryFn: () => api.getMemberProposals(payload),
      enabled: !!payload.unionKey && !!payload.memberKey,
    });
};
export const useGetProposal = (payload: {unionKey: number, proposalKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'ProposalKey', payload.proposalKey, 'GetProposal', payload],
      queryFn: () => api.getProposal(payload),
      enabled: !!payload.unionKey && !!payload.proposalKey,
    });
};
export const useAddReasonOnlyProposal = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addReasonOnlyProposal });
};
export const useAddTextChangeProposal = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addTextChangeProposal });
};
export const useGetMemberProposalVotes = (payload: {unionKey: number, memberKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposalVotes', payload],
      queryFn: () => api.getMemberProposalVotes(payload),
      enabled: !!payload.unionKey && !!payload.memberKey,
    });
};
export const useAddProposalVote = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addProposalVote });
};
export const useGetReviews = (payload: {locationKey: number, businessKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['BusinessKey', payload.businessKey, 'tblReview', 'List', 'GetReviews', payload],
      queryFn: () => api.getReviews(payload),
      enabled: !!payload.locationKey && !!payload.businessKey,
    });
};
export const useAddReview = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addReview });
};
export const useServiceSpamCheckReviews = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.serviceSpamCheckReviews });
};
export const useServiceUpdateProposals = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.serviceUpdateProposals });
};
export const useServiceUpdateElections = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.serviceUpdateElections });
};
export const useServiceUpdateActions = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.serviceUpdateActions });
};
export const useGetSitemap = (payload: {index: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['LocationKey', 'All', 'GetSitemap', payload],
      queryFn: () => api.getSitemap(payload),
      enabled: !!payload.index,
    });
};
export const useGetSitemapIndex = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['LocationKey', 'All', 'GetSitemapIndex'],
      queryFn: () => api.getSitemapIndex(),
      enabled: true,
    });
};
export const useGetUnions = (payload: {locationKey: number, businessKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['BusinessKey', payload.businessKey, 'GetUnions', payload],
      queryFn: () => api.getUnions(payload),
      enabled: !!payload.locationKey && !!payload.businessKey,
    });
};
export const useGetUnion = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'GetUnion', payload],
      queryFn: () => api.getUnion(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetUnionMembership = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['GetUnionMembership'],
      queryFn: () => api.getUnionMembership(),
      enabled: true,
    });
};
export const useAddUnion = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.addUnion });
};
export const useUpdateUnionDetails = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.updateUnionDetails });
};
export const useGetUnionSummary = (payload: {unionKey: number}) => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['UnionKey', payload.unionKey, 'GetUnionSummary', payload],
      queryFn: () => api.getUnionSummary(payload),
      enabled: !!payload.unionKey,
    });
};
export const useGetUser = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useQuery({
      queryKey: ['tblUser', 'All', 'GetUser'],
      queryFn: () => api.getUser(),
      enabled: true,
    });
};
export const useUpdateUserDefaultUnion = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.updateUserDefaultUnion });
};
export const useUpdateUserName = () => {
	const queryClient = useQueryClient();
	const api = new API(queryClient);
    return useMutation({ mutationFn: api.updateUserName });
};

export class API {
    server: boolean;
    baseURL: string;
    queryClient: QueryClient;
    cookies: any;
    constructor(queryClient: QueryClient,
                cookies: string = '') {
        this.queryClient = queryClient;
        this.server = typeof window === "undefined";
        if (this.server)
        {
            axios.defaults.headers.common["Cookie"] = cookies;
            this.cookies = cookie.parse(cookies);
            this.baseURL = process.env.NEXT_PUBLIC_API_URL || "";
        }
        else
        {
            this.baseURL = process.env.NEXT_PUBLIC_URL + '/api/v1';
        }
    }
getActions = (payload: {unionKey: number}): Promise<models.Action[]> =>
    axios.get<models.Action[]>(this.baseURL + "/actions", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetActions = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblAction', 'List', 'GetActions', payload],
          queryFn: () => this.getActions(payload),
        });
    }
    return null;
};
fetchGetActions = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblAction', 'List', 'GetActions', payload],
          queryFn: () => this.getActions(payload),
        });
    }
    return null;
};
getAction = (payload: {actionKey: number}): Promise<models.Action> =>
    axios.get<models.Action>(this.baseURL + "/action", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetAction = async (payload: {actionKey: number}) => {
    if (!!payload.actionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ActionKey', payload.actionKey, 'GetAction', payload],
          queryFn: () => this.getAction(payload),
        });
    }
    return null;
};
fetchGetAction = async (payload: {actionKey: number}) => {
    if (!!payload.actionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ActionKey', payload.actionKey, 'GetAction', payload],
          queryFn: () => this.getAction(payload),
        });
    }
    return null;
};
addActionProposal = (payload: models.ActionProposal): Promise<models.ActionProposal> =>
    axios.post<models.ActionProposal>(this.baseURL + "/action/proposal", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ActionKey', response.data.actionKey, ] });
        return response.data;
    });
withdrawActionProposal = (payload: models.ActionProposal): Promise<models.ActionProposal> =>
    axios.delete<models.ActionProposal>(this.baseURL + "/action/proposal", {
        data: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ActionKey', response.data.actionKey, ] });
        return response.data;
    });
getMemberActionVotes = (payload: {memberKey: number}): Promise<models.ActionVote[]> =>
    axios.get<models.ActionVote[]>(this.baseURL + "/member/action/votes", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberActionVotes = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetMemberActionVotes', payload],
          queryFn: () => this.getMemberActionVotes(payload),
        });
    }
    return null;
};
fetchGetMemberActionVotes = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetMemberActionVotes', payload],
          queryFn: () => this.getMemberActionVotes(payload),
        });
    }
    return null;
};
addActionVote = (payload: models.ActionVote): Promise<models.ActionVote> =>
    axios.post<models.ActionVote>(this.baseURL + "/action/vote", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ActionKey', response.data.actionKey, ] });
        return response.data;
    });
withdrawActionVote = (payload: {unionKey: number, actionKey: number}): Promise<models.ActionVote> =>
    axios.delete<models.ActionVote>(this.baseURL + "/action/vote", {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ActionKey', response.data.actionKey, ] });
        return response.data;
    });
updateMetadataForBusiness = (payload: {businessKey: number}): Promise<void> =>
    axios.post<void>(this.baseURL + "/administration/tools/update-business-metadata", null, {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblBusiness', 'List', ] });
        return response.data;
    });
registerUser = (payload: models.User_Register): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/user", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
sendVerificationCode = (payload: models.User_Email): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/sendverification", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
verifyUserCode = (payload: models.User_Verify): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/verify", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
getFakeName = (): Promise<{name: string}> =>
    axios.get<{name: string}>(this.baseURL + "/auth/fakeName", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetFakeName = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetFakeName'],
          queryFn: () => this.getFakeName(),
        });
    }
};
fetchGetFakeName = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetFakeName'],
          queryFn: () => this.getFakeName(),
        });
    }
};
getIpLocation = (): Promise<{ipLatitude: number, ipLongitude: number}> =>
    axios.get<{ipLatitude: number, ipLongitude: number}>(this.baseURL + "/auth/iplocation", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetIpLocation = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetIpLocation'],
          queryFn: () => this.getIpLocation(),
        });
    }
};
fetchGetIpLocation = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetIpLocation'],
          queryFn: () => this.getIpLocation(),
        });
    }
};
authenticateUserOAuth2 = (payload: models.User_LoginOAuth2): Promise<models.User_Authenticated> =>
    axios.post<models.User_Authenticated>(this.baseURL + "/auth/login/oauth2", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblUser', 'All', ] });
        return response.data;
    });
authenticateUser = (payload: models.User_Login): Promise<models.User_Authenticated> =>
    axios.post<models.User_Authenticated>(this.baseURL + "/auth", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblUser', 'All', ] });
        return response.data;
    });
logout = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/logout", {
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblUser', 'All', ] });
        return response.data;
    });
reAuthenticateUser = (): Promise<models.User_Authenticated> =>
    axios.post<models.User_Authenticated>(this.baseURL + "/auth/reauthenticate", {
    })
    .then((response) => {
        return response.data;
    });
updatePassword = (payload: models.User_ChangePassword): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/password", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
recoverPassword = (payload: models.User_RecoverPassword): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/password/recover", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
resetPassword = (payload: models.User_ResetPassword): Promise<void> =>
    axios.post<void>(this.baseURL + "/auth/password/reset", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
getBenefits = (payload: {locationKey: number, businessKey: number}): Promise<models.Benefit[]> =>
    axios.get<models.Benefit[]>(this.baseURL + "/benefits", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetBenefits = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'tblBenefit', 'List', 'GetBenefits', payload],
          queryFn: () => this.getBenefits(payload),
        });
    }
    return null;
};
fetchGetBenefits = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'tblBenefit', 'List', 'GetBenefits', payload],
          queryFn: () => this.getBenefits(payload),
        });
    }
    return null;
};
addBenefit = (payload: models.Benefit): Promise<models.Benefit> =>
    axios.post<models.Benefit>(this.baseURL + "/benefit", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['BusinessKey', response.data.businessKey, 'tblBenefit', 'List', ] });
        return response.data;
    });
getBusiness = (payload: {businessKey: number}): Promise<models.Business> =>
    axios.get<models.Business>(this.baseURL + "/business", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetBusiness = async (payload: {businessKey: number}) => {
    if (!!payload.businessKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'GetBusiness', payload],
          queryFn: () => this.getBusiness(payload),
        });
    }
    return null;
};
fetchGetBusiness = async (payload: {businessKey: number}) => {
    if (!!payload.businessKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'GetBusiness', payload],
          queryFn: () => this.getBusiness(payload),
        });
    }
    return null;
};
getNoMetadataBusinesses = (): Promise<models.Business[]> =>
    axios.get<models.Business[]>(this.baseURL + "/no-metadata-businesses", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetNoMetadataBusinesses = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['tblBusiness', 'List', 'GetNoMetadataBusinesses'],
          queryFn: () => this.getNoMetadataBusinesses(),
        });
    }
};
fetchGetNoMetadataBusinesses = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['tblBusiness', 'List', 'GetNoMetadataBusinesses'],
          queryFn: () => this.getNoMetadataBusinesses(),
        });
    }
};
getUnReviewedBusinesses = (): Promise<models.Business[]> =>
    axios.get<models.Business[]>(this.baseURL + "/unreviewed-businesses", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnReviewedBusinesses = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['tblBusiness', 'List', 'GetUnReviewedBusinesses'],
          queryFn: () => this.getUnReviewedBusinesses(),
        });
    }
};
fetchGetUnReviewedBusinesses = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['tblBusiness', 'List', 'GetUnReviewedBusinesses'],
          queryFn: () => this.getUnReviewedBusinesses(),
        });
    }
};
searchBusinesses = (payload: {search: string}): Promise<{businessKey: number, name: string, editDistance: number}[]> =>
    axios.get<{businessKey: number, name: string, editDistance: number}[]>(this.baseURL + "/search-businesses", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchSearchBusinesses = async (payload: {search: string}) => {
    if (!!payload.search)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['SearchBusinesses', payload],
          queryFn: () => this.searchBusinesses(payload),
        });
    }
    return null;
};
fetchSearchBusinesses = async (payload: {search: string}) => {
    if (!!payload.search)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['SearchBusinesses', payload],
          queryFn: () => this.searchBusinesses(payload),
        });
    }
    return null;
};
addBusiness = (payload: models.Business_Add): Promise<models.Business_Add> =>
    axios.post<models.Business_Add>(this.baseURL + "/business", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['LocationKey', response.data.locationKey, ] });
        return response.data;
    });
getCalendarEvents = (payload: {unionKey: number}): Promise<models.CalendarEvent[]> =>
    axios.get<models.CalendarEvent[]>(this.baseURL + "/calendar/events", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetCalendarEvents = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblCalendarEvent', 'List', 'GetCalendarEvents', payload],
          queryFn: () => this.getCalendarEvents(payload),
        });
    }
    return null;
};
fetchGetCalendarEvents = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblCalendarEvent', 'List', 'GetCalendarEvents', payload],
          queryFn: () => this.getCalendarEvents(payload),
        });
    }
    return null;
};
getChatMembers = (payload: {chatThreadKey: number}): Promise<models.ChatMember[]> =>
    axios.get<models.ChatMember[]>(this.baseURL + "/chat/members", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatMembers = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'tblChatMember', 'List', 'GetChatMembers', payload],
          queryFn: () => this.getChatMembers(payload),
        });
    }
    return null;
};
fetchGetChatMembers = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'tblChatMember', 'List', 'GetChatMembers', payload],
          queryFn: () => this.getChatMembers(payload),
        });
    }
    return null;
};
getChatMember = (payload: {chatMemberKey: number}): Promise<models.ChatMember> =>
    axios.get<models.ChatMember>(this.baseURL + "/chat/member", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatMember = async (payload: {chatMemberKey: number}) => {
    if (!!payload.chatMemberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ChatMemberKey', payload.chatMemberKey, 'GetChatMember', payload],
          queryFn: () => this.getChatMember(payload),
        });
    }
    return null;
};
fetchGetChatMember = async (payload: {chatMemberKey: number}) => {
    if (!!payload.chatMemberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ChatMemberKey', payload.chatMemberKey, 'GetChatMember', payload],
          queryFn: () => this.getChatMember(payload),
        });
    }
    return null;
};
addChatMember = (payload: models.ChatMember): Promise<models.ChatMember> =>
    axios.post<models.ChatMember>(this.baseURL + "/chat/member", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ChatThreadKey', response.data.chatThreadKey, 'tblChatMember', 'List', ] });
        return response.data;
    });
getUnreadMessages = (): Promise<{unreadMessagesCount: number}> =>
    axios.get<{unreadMessagesCount: number}>(this.baseURL + "/chat/unread/messages", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnreadMessages = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['tblChatMessage', 'List', 'GetUnreadMessages'],
          queryFn: () => this.getUnreadMessages(),
        });
    }
};
fetchGetUnreadMessages = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['tblChatMessage', 'List', 'GetUnreadMessages'],
          queryFn: () => this.getUnreadMessages(),
        });
    }
};
getChatMessages = (payload: {chatThreadKey: number}): Promise<models.ChatMessage[]> =>
    axios.get<models.ChatMessage[]>(this.baseURL + "/chat/messages", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatMessages = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatMessages', payload],
          queryFn: () => this.getChatMessages(payload),
        });
    }
    return null;
};
fetchGetChatMessages = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatMessages', payload],
          queryFn: () => this.getChatMessages(payload),
        });
    }
    return null;
};
getChatMessage = (payload: {chatMessageKey: number}): Promise<models.ChatMessage> =>
    axios.get<models.ChatMessage>(this.baseURL + "/chat/message", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatMessage = async (payload: {chatMessageKey: number}) => {
    if (!!payload.chatMessageKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ChatMessageKey', payload.chatMessageKey, 'GetChatMessage', payload],
          queryFn: () => this.getChatMessage(payload),
        });
    }
    return null;
};
fetchGetChatMessage = async (payload: {chatMessageKey: number}) => {
    if (!!payload.chatMessageKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ChatMessageKey', payload.chatMessageKey, 'GetChatMessage', payload],
          queryFn: () => this.getChatMessage(payload),
        });
    }
    return null;
};
addChatMessageNewThread = (payload: models.ChatMessage_NewThread): Promise<models.ChatMessage_NewThread> =>
    axios.post<models.ChatMessage_NewThread>(this.baseURL + "/chat/messagenewthread", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblChatThread', 'List', ] });
        return response.data;
    });
addChatMessage = (payload: models.ChatMessage_NewMessage): Promise<models.ChatMessage_NewMessage> =>
    axios.post<models.ChatMessage_NewMessage>(this.baseURL + "/chat/message", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ChatThreadKey', response.data.chatThreadKey, ] });
		this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblChatThread', 'List', ] });
        return response.data;
    });
getChatThreads = (payload: {unionKey: number}): Promise<models.ChatMember_ThreadMembership[]> =>
    axios.get<models.ChatMember_ThreadMembership[]>(this.baseURL + "/chat/threads", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatThreads = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblChatThread', 'List', 'GetChatThreads', payload],
          queryFn: () => this.getChatThreads(payload),
        });
    }
    return null;
};
fetchGetChatThreads = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblChatThread', 'List', 'GetChatThreads', payload],
          queryFn: () => this.getChatThreads(payload),
        });
    }
    return null;
};
getChatThread = (payload: {chatThreadKey: number}): Promise<models.ChatThread> =>
    axios.get<models.ChatThread>(this.baseURL + "/chat/thread", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetChatThread = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatThread', payload],
          queryFn: () => this.getChatThread(payload),
        });
    }
    return null;
};
fetchGetChatThread = async (payload: {chatThreadKey: number}) => {
    if (!!payload.chatThreadKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ChatThreadKey', payload.chatThreadKey, 'GetChatThread', payload],
          queryFn: () => this.getChatThread(payload),
        });
    }
    return null;
};
addChatThread = (payload: models.ChatThread): Promise<models.ChatThread> =>
    axios.post<models.ChatThread>(this.baseURL + "/chat/thread", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblChatThread', 'List', ] });
        return response.data;
    });
addElectionCandidacy = (payload: models.ElectionCandidacy): Promise<models.ElectionCandidacy> =>
    axios.post<models.ElectionCandidacy>(this.baseURL + "/election/candidacy", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ElectionKey', response.data.electionKey, ] });
        return response.data;
    });
withdrawElectionCandidacy = (payload: models.ElectionCandidacy): Promise<models.ElectionCandidacy> =>
    axios.delete<models.ElectionCandidacy>(this.baseURL + "/election/candidacy", {
        data: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ElectionKey', response.data.electionKey, ] });
        return response.data;
    });
getElections = (payload: {unionKey: number}): Promise<models.Election[]> =>
    axios.get<models.Election[]>(this.baseURL + "/elections", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetElections = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblElection', 'List', 'GetElections', payload],
          queryFn: () => this.getElections(payload),
        });
    }
    return null;
};
fetchGetElections = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblElection', 'List', 'GetElections', payload],
          queryFn: () => this.getElections(payload),
        });
    }
    return null;
};
getElection = (payload: {electionKey: number}): Promise<models.Election> =>
    axios.get<models.Election>(this.baseURL + "/election", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetElection = async (payload: {electionKey: number}) => {
    if (!!payload.electionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['ElectionKey', payload.electionKey, 'GetElection', payload],
          queryFn: () => this.getElection(payload),
        });
    }
    return null;
};
fetchGetElection = async (payload: {electionKey: number}) => {
    if (!!payload.electionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['ElectionKey', payload.electionKey, 'GetElection', payload],
          queryFn: () => this.getElection(payload),
        });
    }
    return null;
};
getMemberElectionVotes = (payload: {memberKey: number}): Promise<models.ElectionVote[]> =>
    axios.get<models.ElectionVote[]>(this.baseURL + "/member/election/votes", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberElectionVotes = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetMemberElectionVotes', payload],
          queryFn: () => this.getMemberElectionVotes(payload),
        });
    }
    return null;
};
fetchGetMemberElectionVotes = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetMemberElectionVotes', payload],
          queryFn: () => this.getMemberElectionVotes(payload),
        });
    }
    return null;
};
addElectionVote = (payload: models.ElectionVote): Promise<models.ElectionVote> =>
    axios.post<models.ElectionVote>(this.baseURL + "/election/vote", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ElectionKey', response.data.electionKey, ] });
        return response.data;
    });
withdrawElectionVote = (payload: {unionKey: number, electionKey: number}): Promise<models.ElectionVote> =>
    axios.delete<models.ElectionVote>(this.baseURL + "/election/vote", {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['ElectionKey', response.data.electionKey, ] });
        return response.data;
    });
getLocationURL = (payload: {houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, latitude: number, longitude: number}): Promise<{locationKey: number}> =>
    axios.get<{locationKey: number}>(this.baseURL + "/location/url", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationURL = async (payload: {houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, latitude: number, longitude: number}) => {
    if (!!payload.houseNumber && !!payload.street && !!payload.city && !!payload.region && !!payload.postCode && !!payload.country && !!payload.latitude && !!payload.longitude)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetLocationURL', payload],
          queryFn: () => this.getLocationURL(payload),
        });
    }
    return null;
};
fetchGetLocationURL = async (payload: {houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, latitude: number, longitude: number}) => {
    if (!!payload.houseNumber && !!payload.street && !!payload.city && !!payload.region && !!payload.postCode && !!payload.country && !!payload.latitude && !!payload.longitude)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetLocationURL', payload],
          queryFn: () => this.getLocationURL(payload),
        });
    }
    return null;
};
getLocationsInCoordBoundingBox = (payload: {bottomLat: number, topLat: number, leftLon: number, rightLon: number}): Promise<{locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, businessCount: number, unionCount: number}[]> =>
    axios.get<{locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, businessCount: number, unionCount: number}[]>(this.baseURL + "/locations", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationsInCoordBoundingBox = async (payload: {bottomLat: number, topLat: number, leftLon: number, rightLon: number}) => {
    if (!!payload.bottomLat && !!payload.topLat && !!payload.leftLon && !!payload.rightLon)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetLocationsInCoordBoundingBox', payload],
          queryFn: () => this.getLocationsInCoordBoundingBox(payload),
        });
    }
    return null;
};
fetchGetLocationsInCoordBoundingBox = async (payload: {bottomLat: number, topLat: number, leftLon: number, rightLon: number}) => {
    if (!!payload.bottomLat && !!payload.topLat && !!payload.leftLon && !!payload.rightLon)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetLocationsInCoordBoundingBox', payload],
          queryFn: () => this.getLocationsInCoordBoundingBox(payload),
        });
    }
    return null;
};
getLocationsByCountryAndRegion = (payload: {country: string, region: string}): Promise<{locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, businessCount: number, unionCount: number}[]> =>
    axios.get<{locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string, businessCount: number, unionCount: number}[]>(this.baseURL + "/locations/country/region", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationsByCountryAndRegion = async (payload: {country: string, region: string}) => {
    if (!!payload.country && !!payload.region)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetLocationsByCountryAndRegion', payload],
          queryFn: () => this.getLocationsByCountryAndRegion(payload),
        });
    }
    return null;
};
fetchGetLocationsByCountryAndRegion = async (payload: {country: string, region: string}) => {
    if (!!payload.country && !!payload.region)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetLocationsByCountryAndRegion', payload],
          queryFn: () => this.getLocationsByCountryAndRegion(payload),
        });
    }
    return null;
};
getLocationCountByCountry = (): Promise<{country: string, locationCount: number}[]> =>
    axios.get<{country: string, locationCount: number}[]>(this.baseURL + "/location/count/country", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationCountByCountry = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetLocationCountByCountry'],
          queryFn: () => this.getLocationCountByCountry(),
        });
    }
};
fetchGetLocationCountByCountry = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetLocationCountByCountry'],
          queryFn: () => this.getLocationCountByCountry(),
        });
    }
};
getLocationCountByRegion = (payload: {country: string}): Promise<{region: string, locationCount: number}[]> =>
    axios.get<{region: string, locationCount: number}[]>(this.baseURL + "/location/count/region", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationCountByRegion = async (payload: {country: string}) => {
    if (!!payload.country)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetLocationCountByRegion', payload],
          queryFn: () => this.getLocationCountByRegion(payload),
        });
    }
    return null;
};
fetchGetLocationCountByRegion = async (payload: {country: string}) => {
    if (!!payload.country)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetLocationCountByRegion', payload],
          queryFn: () => this.getLocationCountByRegion(payload),
        });
    }
    return null;
};
getLocation = (payload: {locationKey: number}): Promise<models.Location> =>
    axios.get<models.Location>(this.baseURL + "/location", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocation = async (payload: {locationKey: number}) => {
    if (!!payload.locationKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['LocationKey', payload.locationKey, 'GetLocation', payload],
          queryFn: () => this.getLocation(payload),
        });
    }
    return null;
};
fetchGetLocation = async (payload: {locationKey: number}) => {
    if (!!payload.locationKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['LocationKey', payload.locationKey, 'GetLocation', payload],
          queryFn: () => this.getLocation(payload),
        });
    }
    return null;
};
getLocationBusinesses = (payload: {locationKey: number}): Promise<models.LocationToBusiness[]> =>
    axios.get<models.LocationToBusiness[]>(this.baseURL + "/location/businesses", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetLocationBusinesses = async (payload: {locationKey: number}) => {
    if (!!payload.locationKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['LocationKey', payload.locationKey, 'GetLocationBusinesses', payload],
          queryFn: () => this.getLocationBusinesses(payload),
        });
    }
    return null;
};
fetchGetLocationBusinesses = async (payload: {locationKey: number}) => {
    if (!!payload.locationKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['LocationKey', payload.locationKey, 'GetLocationBusinesses', payload],
          queryFn: () => this.getLocationBusinesses(payload),
        });
    }
    return null;
};
addLocation = (payload: models.Location_Add): Promise<{locationKey: number}> =>
    axios.post<{locationKey: number}>(this.baseURL + "/location", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['LocationKey', response.data.locationKey, ] });
        return response.data;
    });
addLocationToBusiness = (payload: models.LocationToBusiness): Promise<{locationKey: number}> =>
    axios.post<{locationKey: number}>(this.baseURL + "/location/business", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['LocationKey', response.data.locationKey, ] });
        return response.data;
    });
getUnionMembers = (payload: {unionKey: number}): Promise<models.Member[]> =>
    axios.get<models.Member[]>(this.baseURL + "/members", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnionMembers = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetUnionMembers', payload],
          queryFn: () => this.getUnionMembers(payload),
        });
    }
    return null;
};
fetchGetUnionMembers = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetUnionMembers', payload],
          queryFn: () => this.getUnionMembers(payload),
        });
    }
    return null;
};
getUnionMember = (payload: {memberKey: number}): Promise<models.Member> =>
    axios.get<models.Member>(this.baseURL + "/member", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnionMember = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetUnionMember', payload],
          queryFn: () => this.getUnionMember(payload),
        });
    }
    return null;
};
fetchGetUnionMember = async (payload: {memberKey: number}) => {
    if (!!payload.memberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetUnionMember', payload],
          queryFn: () => this.getUnionMember(payload),
        });
    }
    return null;
};
getUnionLeaders = (payload: {businessKey: number}): Promise<{unionName: string, unionKey: number, memberName: string, memberKey: number}[]> =>
    axios.get<{unionName: string, unionKey: number, memberName: string, memberKey: number}[]>(this.baseURL + "/leaders", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnionLeaders = async (payload: {businessKey: number}) => {
    if (!!payload.businessKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetUnionLeaders', payload],
          queryFn: () => this.getUnionLeaders(payload),
        });
    }
    return null;
};
fetchGetUnionLeaders = async (payload: {businessKey: number}) => {
    if (!!payload.businessKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetUnionLeaders', payload],
          queryFn: () => this.getUnionLeaders(payload),
        });
    }
    return null;
};
addUnionMember = (payload: {unionKey: number}): Promise<{memberKey: number}> =>
    axios.post<{memberKey: number}>(this.baseURL + "/member", null, {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
inviteUnionMember = (payload: {unionKey: number, email: string}): Promise<void> =>
    axios.post<void>(this.baseURL + "/member/invite", null, {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
getMemberBusinesses = (payload: {userKey: number}): Promise<{memberKey: number, unionKey: number, locationKey: number, businessKey: number, name: string}[]> =>
    axios.get<{memberKey: number, unionKey: number, locationKey: number, businessKey: number, name: string}[]>(this.baseURL + "/member/businesses", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberBusinesses = async (payload: {userKey: number}) => {
    if (!!payload.userKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UserKey', payload.userKey, 'GetMemberBusinesses', payload],
          queryFn: () => this.getMemberBusinesses(payload),
        });
    }
    return null;
};
fetchGetMemberBusinesses = async (payload: {userKey: number}) => {
    if (!!payload.userKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UserKey', payload.userKey, 'GetMemberBusinesses', payload],
          queryFn: () => this.getMemberBusinesses(payload),
        });
    }
    return null;
};
getMemberLocations = (payload: {userKey: number}): Promise<{memberKey: number, unionKey: number, locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string}[]> =>
    axios.get<{memberKey: number, unionKey: number, locationKey: number, latitude: number, longitude: number, url: string, houseNumber: string, street: string, city: string, region: string, postCode: string, country: string}[]>(this.baseURL + "/member/locations", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberLocations = async (payload: {userKey: number}) => {
    if (!!payload.userKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UserKey', payload.userKey, 'GetMemberLocations', payload],
          queryFn: () => this.getMemberLocations(payload),
        });
    }
    return null;
};
fetchGetMemberLocations = async (payload: {userKey: number}) => {
    if (!!payload.userKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UserKey', payload.userKey, 'GetMemberLocations', payload],
          queryFn: () => this.getMemberLocations(payload),
        });
    }
    return null;
};
reviewBusiness = (payload: {businessKey: number, approve: boolean}): Promise<void> =>
    axios.post<void>(this.baseURL + "/moderation/review/business", null, {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblBusiness', 'List', ] });
        return response.data;
    });
getNotifications = (): Promise<models.Notification[]> =>
    axios.get<models.Notification[]>(this.baseURL + "/notifications", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetNotifications = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['tblNotification', 'List', 'GetNotifications'],
          queryFn: () => this.getNotifications(),
        });
    }
};
fetchGetNotifications = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['tblNotification', 'List', 'GetNotifications'],
          queryFn: () => this.getNotifications(),
        });
    }
};
dismissNotification = (payload: {notificationKey: number}): Promise<void> =>
    axios.delete<void>(this.baseURL + "/notification", {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblNotification', 'List', ] });
        return response.data;
    });
dismissAllNotifications = (): Promise<void> =>
    axios.delete<void>(this.baseURL + "/notifications", {
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblNotification', 'List', ] });
        return response.data;
    });
createCheckoutSession = (payload: models.Payment_CheckoutSession): Promise<{url: string}> =>
    axios.post<{url: string}>(this.baseURL + "/payment/create-checkout-session", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
createCustomerPortal = (payload: models.Payment_CustomerPortal): Promise<{url: string}> =>
    axios.post<{url: string}>(this.baseURL + "/payment/create-customer-portal-session", {
        ...payload,
    })
    .then((response) => {
        return response.data;
    });
paymentEvents = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/payment/events", {
    })
    .then((response) => {
        return response.data;
    });
getProposals = (payload: {unionKey: number}): Promise<models.Proposal[]> =>
    axios.get<models.Proposal[]>(this.baseURL + "/proposals", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetProposals = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'GetProposals', payload],
          queryFn: () => this.getProposals(payload),
        });
    }
    return null;
};
fetchGetProposals = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'GetProposals', payload],
          queryFn: () => this.getProposals(payload),
        });
    }
    return null;
};
getMemberProposals = (payload: {unionKey: number, memberKey: number}): Promise<models.Proposal[]> =>
    axios.get<models.Proposal[]>(this.baseURL + "/member/proposals", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberProposals = async (payload: {unionKey: number, memberKey: number}) => {
    if (!!payload.unionKey && !!payload.memberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposals', payload],
          queryFn: () => this.getMemberProposals(payload),
        });
    }
    return null;
};
fetchGetMemberProposals = async (payload: {unionKey: number, memberKey: number}) => {
    if (!!payload.unionKey && !!payload.memberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposals', payload],
          queryFn: () => this.getMemberProposals(payload),
        });
    }
    return null;
};
getProposal = (payload: {unionKey: number, proposalKey: number}): Promise<models.Proposal> =>
    axios.get<models.Proposal>(this.baseURL + "/proposal", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetProposal = async (payload: {unionKey: number, proposalKey: number}) => {
    if (!!payload.unionKey && !!payload.proposalKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'ProposalKey', payload.proposalKey, 'GetProposal', payload],
          queryFn: () => this.getProposal(payload),
        });
    }
    return null;
};
fetchGetProposal = async (payload: {unionKey: number, proposalKey: number}) => {
    if (!!payload.unionKey && !!payload.proposalKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'ProposalKey', payload.proposalKey, 'GetProposal', payload],
          queryFn: () => this.getProposal(payload),
        });
    }
    return null;
};
addReasonOnlyProposal = (payload: models.Proposal_Reason): Promise<models.Proposal_Reason> =>
    axios.post<models.Proposal_Reason>(this.baseURL + "/proposal/reason", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblProposal', 'List', ] });
        return response.data;
    });
addTextChangeProposal = (payload: models.Proposal_TextChanges): Promise<models.Proposal_Reason> =>
    axios.post<models.Proposal_Reason>(this.baseURL + "/proposal/text", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblProposal', 'List', ] });
        return response.data;
    });
getMemberProposalVotes = (payload: {unionKey: number, memberKey: number}): Promise<models.ProposalVote[]> =>
    axios.get<models.ProposalVote[]>(this.baseURL + "/member/proposal/votes", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetMemberProposalVotes = async (payload: {unionKey: number, memberKey: number}) => {
    if (!!payload.unionKey && !!payload.memberKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposalVotes', payload],
          queryFn: () => this.getMemberProposalVotes(payload),
        });
    }
    return null;
};
fetchGetMemberProposalVotes = async (payload: {unionKey: number, memberKey: number}) => {
    if (!!payload.unionKey && !!payload.memberKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'tblProposal', 'List', 'MemberKey', payload.memberKey, 'GetMemberProposalVotes', payload],
          queryFn: () => this.getMemberProposalVotes(payload),
        });
    }
    return null;
};
addProposalVote = (payload: models.ProposalVote): Promise<{unionKey: string}> =>
    axios.post<{unionKey: string}>(this.baseURL + "/proposal/vote", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, 'tblProposal', 'List', ] });
        return response.data;
    });
getReviews = (payload: {locationKey: number, businessKey: number}): Promise<models.Review[]> =>
    axios.get<models.Review[]>(this.baseURL + "/reviews", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetReviews = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'tblReview', 'List', 'GetReviews', payload],
          queryFn: () => this.getReviews(payload),
        });
    }
    return null;
};
fetchGetReviews = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'tblReview', 'List', 'GetReviews', payload],
          queryFn: () => this.getReviews(payload),
        });
    }
    return null;
};
addReview = (payload: models.Review): Promise<models.Review> =>
    axios.post<models.Review>(this.baseURL + "/review", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['BusinessKey', response.data.businessKey, 'tblReview', 'List', ] });
        return response.data;
    });
serviceSpamCheckReviews = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/service/spam/reviews", {
    })
    .then((response) => {
        return response.data;
    });
serviceUpdateProposals = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/service/proposals", {
    })
    .then((response) => {
        return response.data;
    });
serviceUpdateElections = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/service/elections", {
    })
    .then((response) => {
        return response.data;
    });
serviceUpdateActions = (): Promise<void> =>
    axios.post<void>(this.baseURL + "/service/actions", {
    })
    .then((response) => {
        return response.data;
    });
getSitemap = (payload: {index: number}): Promise<{url: string}[]> =>
    axios.get<{url: string}[]>(this.baseURL + "/sitemap", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetSitemap = async (payload: {index: number}) => {
    if (!!payload.index)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['LocationKey', 'All', 'GetSitemap', payload],
          queryFn: () => this.getSitemap(payload),
        });
    }
    return null;
};
fetchGetSitemap = async (payload: {index: number}) => {
    if (!!payload.index)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['LocationKey', 'All', 'GetSitemap', payload],
          queryFn: () => this.getSitemap(payload),
        });
    }
    return null;
};
getSitemapIndex = (): Promise<{index: number}> =>
    axios.get<{index: number}>(this.baseURL + "/sitemap/index", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetSitemapIndex = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['LocationKey', 'All', 'GetSitemapIndex'],
          queryFn: () => this.getSitemapIndex(),
        });
    }
};
fetchGetSitemapIndex = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['LocationKey', 'All', 'GetSitemapIndex'],
          queryFn: () => this.getSitemapIndex(),
        });
    }
};
getUnions = (payload: {locationKey: number, businessKey: number}): Promise<models.LocationToBusiness> =>
    axios.get<models.LocationToBusiness>(this.baseURL + "/unions", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnions = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'GetUnions', payload],
          queryFn: () => this.getUnions(payload),
        });
    }
    return null;
};
fetchGetUnions = async (payload: {locationKey: number, businessKey: number}) => {
    if (!!payload.locationKey && !!payload.businessKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['BusinessKey', payload.businessKey, 'GetUnions', payload],
          queryFn: () => this.getUnions(payload),
        });
    }
    return null;
};
getUnion = (payload: {unionKey: number}): Promise<models.Union> =>
    axios.get<models.Union>(this.baseURL + "/union", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnion = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'GetUnion', payload],
          queryFn: () => this.getUnion(payload),
        });
    }
    return null;
};
fetchGetUnion = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'GetUnion', payload],
          queryFn: () => this.getUnion(payload),
        });
    }
    return null;
};
getUnionMembership = (): Promise<models.Union_Membership[]> =>
    axios.get<models.Union_Membership[]>(this.baseURL + "/union/membership", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnionMembership = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['GetUnionMembership'],
          queryFn: () => this.getUnionMembership(),
        });
    }
};
fetchGetUnionMembership = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['GetUnionMembership'],
          queryFn: () => this.getUnionMembership(),
        });
    }
};
addUnion = (payload: models.Union_Add): Promise<models.Union> =>
    axios.post<models.Union>(this.baseURL + "/union", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['BusinessKey', response.data.businessKey, ] });
        return response.data;
    });
updateUnionDetails = (payload: models.Union_Details): Promise<models.Union_Details> =>
    axios.post<models.Union_Details>(this.baseURL + "/union/details", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['UnionKey', response.data.unionKey, ] });
        return response.data;
    });
getUnionSummary = (payload: {unionKey: number}): Promise<{unionKey: number, memberCount: number, proposalCount: number, actionCount: number, electionCount: number}> =>
    axios.get<{unionKey: number, memberCount: number, proposalCount: number, actionCount: number, electionCount: number}>(this.baseURL + "/union/summary", {
        params: {...payload},
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUnionSummary = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'GetUnionSummary', payload],
          queryFn: () => this.getUnionSummary(payload),
        });
    }
    return null;
};
fetchGetUnionSummary = async (payload: {unionKey: number}) => {
    if (!!payload.unionKey)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['UnionKey', payload.unionKey, 'GetUnionSummary', payload],
          queryFn: () => this.getUnionSummary(payload),
        });
    }
    return null;
};
getUser = (): Promise<models.User_Authenticated> =>
    axios.get<models.User_Authenticated>(this.baseURL + "/user", {
    })
    .then((response) => {
        return response.data;
    });
prefetchGetUser = async () => {
    if (true)
    {
        return await this.queryClient.prefetchQuery({
          queryKey: ['tblUser', 'All', 'GetUser'],
          queryFn: () => this.getUser(),
        });
    }
};
fetchGetUser = async () => {
    if (true)
    {
        return await this.queryClient.fetchQuery({
          queryKey: ['tblUser', 'All', 'GetUser'],
          queryFn: () => this.getUser(),
        });
    }
};
updateUserDefaultUnion = (payload: {defaultUnion: number}): Promise<{defaultUnion: number}> =>
    axios.post<{defaultUnion: number}>(this.baseURL + "/user/defaultUnion", null, {
        params: {...payload},
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblUser', 'All', ] });
        return response.data;
    });
updateUserName = (payload: models.User_NameChange): Promise<models.User_NameChange> =>
    axios.post<models.User_NameChange>(this.baseURL + "/user/name", {
        ...payload,
    })
    .then((response) => {
        this.queryClient.invalidateQueries({ queryKey: ['tblUser', 'All', ] });
        return response.data;
    });

}
