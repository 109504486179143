import type { FC } from "react";
import { useTheme } from "@mui/material/styles";

export const Logo: FC = () => {
   const theme = useTheme();
   const fillColor = theme.palette.primary.main;

   return (
      <svg viewBox="0 0 122.88 122.88">
         <title>Logo</title>
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.27,37.41a3.32,3.32,0,0,1,1.07.12,4.79,4.79,0,0,1,2.08,1.35,25.41,25.41,0,0,1,3.41,3.85c1.44,1.91,7,8.94,7.75,10.56a2.91,2.91,0,0,1-.41,3.43l-3.28,3.19c-1.3,1.29-3,1.22-4.74-.07-4.2-4.06-8-9.13-11.41-12.75a3.52,3.52,0,0,1-1.14-3.65c.55-2.07,4.58-5.89,6.67-6ZM61.44,0A61.46,61.46,0,1,1,18,18,61.21,61.21,0,0,1,61.44,0ZM98.2,24.68a52,52,0,1,0-45.41,88L47.9,97.63c-1.06-2.48-1.84-4.79-2.8-7.24a49.35,49.35,0,0,0-7-11.35,28.21,28.21,0,0,1-3.39-13.58h0c1.71,1.29,3.59,2.85,5.44,4.3,3.45,2.71,7.1,6,10.71,1.23.68-1,1.38-1.91,2.1-2.8a49.65,49.65,0,0,1,9.26-8.89c6.26-.18,13.12-4.45,18.63-3.72l-2.09-4.93-7,2.64c-5.45,2.05-11.64-5.24-8.9-8.64,9.37-5.37,21.88-15.47,27.94-4.52l5,9.06c6.59,11.74,2.24,16-5.9,27.36a79.1,79.1,0,0,1-10.2,11.76c-1.92,1.79-1.61,1.22-.15,3.57l8.14,14.48A52,52,0,0,0,98.2,24.68ZM34.7,50a3.06,3.06,0,0,1,1.79-.89,3.35,3.35,0,0,1,1.07.12,4.76,4.76,0,0,1,2.07,1.35A105.55,105.55,0,0,1,50.27,61.33c1.79,1.64,2.2,3.35.8,5l-2.56,2.86c-1.3,1.3-2.95,1.23-4.74-.07C35.68,62.31,26.12,58.81,34.7,50ZM72.33,19.39a3.37,3.37,0,0,1,1.09.28c1.83.81,4,4.51,4.86,6.21.59,1.08,2.25,4,3.6,6.5L70.31,37c-1.86-3-3.52-6.07-5.21-8.52a3.69,3.69,0,0,1-.62-3.94c.66-1.5,2.31-2.62,3.66-3.46,1-.58,3.14-1.83,4.19-1.73ZM58.62,27.07a3.42,3.42,0,0,1,1.1.22,4.8,4.8,0,0,1,2,1.59A26.59,26.59,0,0,1,65,33.18c.57.9,1.81,2.82,3.1,4.86-4,1.8-9.37,2.77-9.55,7.89-2.36-3.21-4.46-6.65-6.53-9.29a3.7,3.7,0,0,1-.86-3.9c.83-2.25,5.26-5.71,7.5-5.67Z"
            fill={fillColor}
         />
      </svg>
   );
};
