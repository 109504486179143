import { tokens } from "../tokens";

export const es = {
   [tokens.common.languageChanged]: "Se ha cambiado el idioma",
   [tokens.nav.academy]: "Academia",
   [tokens.nav.account]: "Cuenta",
   [tokens.nav.analytics]: "Analítica",
   [tokens.nav.auth]: "Autenticación",
   [tokens.nav.blog]: "Blog",
   [tokens.nav.browse]: "Buscar",
   [tokens.nav.calendar]: "Calendario",
   [tokens.nav.chat]: "Chat",
   [tokens.nav.checkout]: "Pago",
   [tokens.nav.union]: "Union",
   [tokens.nav.contact]: "Contacto",
   [tokens.nav.course]: "Curso",
   [tokens.nav.create]: "Crear",
   [tokens.nav.crypto]: "Crypto",
   [tokens.nav.members]: "Members",
   [tokens.nav.invite]: "Invite",
   [tokens.nav.dashboard]: "Dashboard",
   [tokens.nav.actions]: "Actions",
   [tokens.nav.elections]: "Elections",
   [tokens.nav.proposals]: "Proposals",
   [tokens.nav.details]: "Detalles",
   [tokens.nav.ecommerce]: "E-Commerce",
   [tokens.nav.edit]: "Editar",
   [tokens.nav.error]: "Error",
   [tokens.nav.feed]: "Fuente social",
   [tokens.nav.fileManager]: "Administrador de Archivos",
   [tokens.nav.finance]: "Finanzas",
   [tokens.nav.fleet]: "Parque",
   [tokens.nav.forgotPassword]: "Recuperar Contraseña",
   [tokens.nav.invoiceList]: "Facturas",
   [tokens.nav.jobList]: "Listado de Trabajos",
   [tokens.nav.kanban]: "Kanban",
   [tokens.nav.list]: "Lista",
   [tokens.nav.login]: "Acceso",
   [tokens.nav.logistics]: "Logística",
   [tokens.nav.mail]: "Correo",
   [tokens.nav.management]: "Gestión",
   [tokens.nav.orderList]: "Pedidos",
   [tokens.nav.overview]: "Visión general",
   [tokens.nav.pages]: "Páginas",
   [tokens.nav.representative]: "Representative",
   [tokens.nav.postCreate]: "Crear Articulo",
   [tokens.nav.postDetails]: "Detalles del Articulo",
   [tokens.nav.postList]: "Lista de Articulos",
   [tokens.nav.pricing]: "Precios",
   [tokens.nav.productList]: "Productos",
   [tokens.nav.profile]: "Perfil",
   [tokens.nav.register]: "Registrarse",
   [tokens.nav.resetPassword]: "Restablecer Contraseña",
   [tokens.nav.socialMedia]: "Redes Sociales",
   [tokens.nav.verifyCode]: "Verificar Código",
   [tokens.nav.administration]: "Administration",
   [tokens.nav.moderation]: "Moderation",
   [tokens.nav.businesses]: "Businesses",
   [tokens.nav.tools]: "Tools",
   [tokens.nav.updateBusinessMetadata]: "Update Business Metadata",
};
