export const tokens = {
   common: {
      languageChanged: "common.languageChanged",
   },
   nav: {
      academy: "nav.academy",
      account: "nav.account",
      analytics: "nav.analytics",
      auth: "nav.auth",
      blog: "nav.blog",
      browse: "nav.browse",
      calendar: "nav.calendar",
      chat: "nav.chat",
      checkout: "nav.checkout",
      union: "nav.union",
      contact: "nav.contact",
      course: "nav.course",
      create: "nav.create",
      crypto: "nav.crypto",
      members: "nav.members",
      invite: "nav.invite",
      dashboard: "nav.dashboard",
      actions: "nav.actions",
      elections: "nav.elections",
      details: "nav.details",
      ecommerce: "nav.ecommerce",
      edit: "nav.edit",
      error: "nav.error",
      feed: "nav.feed",
      fileManager: "nav.fileManager",
      files: "nav.files",
      finance: "nav.finance",
      fleet: "nav.fleet",
      forgotPassword: "nav.forgotPassword",
      invoiceList: "nav.invoices",
      jobList: "nav.jobList",
      kanban: "nav.kanban",
      list: "nav.list",
      login: "nav.login",
      logistics: "nav.logistics",
      mail: "nav.mail",
      management: "nav.management",
      orderList: "nav.orders",
      overview: "nav.overview",
      pages: "nav.pages",
      representative: "nav.representative",
      postCreate: "nav.postCreate",
      postDetails: "nav.postDetails",
      postList: "nav.postList",
      pricing: "nav.pricing",
      productList: "nav.products",
      proposals: "nav.proposals",
      profile: "nav.profile",
      register: "nav.register",
      resetPassword: "nav.resetPassword",
      socialMedia: "nav.socialMedia",
      verifyCode: "nav.verifyCode",
      administration: "nav.administration",
      moderation: "nav.moderation",
      businesses: "nav.businesses",
      tools: "nav.tools",
      updateBusinessMetadata: "nav.updateBusinessMetadata",
   },
};
